<template>
  <v-card @click="showPlaylist(item)" :class="$vuetify.breakpoint.mobile ? 'ma-0 pa-0' : 'ma-4'" @mouseover="hover = true" @mouseleave="hover = null">

    <v-list-item v-if="$vuetify.breakpoint.mobile">
      <v-list-item-avatar tile left :size="imageSize">
        <v-img :height="imageSize" :width="imageSize" :src="albumArt">
          <template v-slot:placeholder>
            <v-sheet>
              <v-skeleton-loader 
                :height="imageSize" 
                :width="imageSize"
                type="image" />
            </v-sheet>
          </template>
        </v-img>
      </v-list-item-avatar>
      <v-list-item-content class="py-0">
        <v-list-item-title class="item-texts">{{ Lget(item, 'name', '') }}</v-list-item-title>
        <v-list-item-subtitle v-if="showOwner" class="shortcut-texts">{{ $t('playlist.by') }} {{ Lget(item,'owner.username', '') }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <template v-else>
      <v-img height="200" width="200" :src="albumArt" transition="fade-transition" origin="center center">
        <template v-if="closeable">
          <v-btn v-show="hover" class="mt-6 mr-n2" fab absolute x-small top right @click.native.stop="$emit('close', item)" transition="fade-transition">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-img>
      <div class="card-text-cnt">
        <v-card-title class="card-text-item my-0 pt-2 pb-0 ml-n4">{{ Lget(item, 'name', '') }}</v-card-title>
        <v-card-subtitle v-if="showOwner" class="shortcut-texts mt-0 ml-n4">{{ $t('playlist.by') }} {{ Lget(item,'owner.username', '') }}</v-card-subtitle>
      </div>
    </template>
  
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import Lget from 'lodash/get'
import { mdiClose } from '@mdi/js'
import LisEmpty from 'lodash/isEmpty'

export default {

  props: {
    item: Object,
    showOwner: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    hover: false,
    mdiClose
  }),

  computed: {
    imageSize() {
      return this.$vuetify.breakpoint.mobile ? 60 : 80
    },

    albumArt() {
      const cover = Lget(this, 'item.imageSrc', null)
      const emptyCover = require("@/assets/images/album.png")
      return LisEmpty(cover) ? emptyCover : cover
    }

  },

  methods: {
    Lget: Lget,

    showPlaylist(playlist) {
      this.$nextTick(() => {
        this.$emit('clicked', {type: 'playlist', ...playlist})
        this.$router.push({ name: 'Playlist', params: {id: playlist.id} }).catch(err => {})
      })
    }
  }
}
</script>

<style scoped>
.item-texts {
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-text-cnt {
  max-width: 200px;
}
.card-text-item {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<template>
  <v-responsive :aspect-ratio="aspectRatio">
    <v-card
      class="genre-card"
      :color="Lget(cardData, 'backgroundColor', 'blue')"
      width="100%"
      height="100%"
      evelation="2" 
      rounded="lg"
      :to="{name: 'Genre', params: {genre: Lget(cardData, 'genre', 'Rock')} }">
      <div :class="`${headingClass} genre-title font-weight-black ${$vuetify.breakpoint.mobile ? 'pt-2 pl-2' : 'pt-4 pl-4'}`">
        {{ Lget(cardData, 'genre', '') }}
      </div>
      <v-img class="genre-image" :min-height="imageSize" :min-width="imageSize" contain :height="imageSize" :width="imageSize" :src="Lget(cardData, 'imageSrc', '')" />
    </v-card>
  </v-responsive>
</template>

<script>
import Lget from 'lodash/get'

export default {
  props: {
    aspectRatio: Number|String,
    imageSize: Number,
    cardData: Object,
    headingClass: {
      type: String,
      default: 'text-h4'
    }
  },

  methods: {
    Lget: Lget
  }

}
</script>

<style scoped>
  .genre-title {
    font-family: "Roboto Condensed", sans-serif !important;
  }
  .genre-image {
    transform: rotate(20deg);
    position: absolute;
    bottom: -10px;
    right: -20px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.7));
  }
  a.genre-card:hover {
    text-decoration: none !important;
  }
</style>
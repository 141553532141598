<template>
  <v-card fluid class="fill-height view search px-4 pb-12 mt-12 mb-12">
    <TopBar>
      <template v-slot:title>{{ $t('about.label') }}</template>
    </TopBar>

    <v-card flat>
      <h5 class="pt-4">{{ $t('about.label') }}</h5>

      <v-card class="d-flex justify-center">
        <div class="animation-container">
          <div class="ball">
            <img src="@/assets/images/logo.png" />
          </div>
          <div class="letter letterM">
            M
          </div>
          <div class="letter letterU">
            u
          </div>
          <div class="letter letterC">
            C
          </div>
          <div class="letter letterO">
            o
          </div>
        </div>
      </v-card>
      <v-card class="d-flex justify-center">
        <div class="text-container">
          <p>{{ $t('app.appname') }} {{  $t('app.version') }} <v-btn class="mb-1 sneaky-button" text @click="newsDialog=true"><span class="obscured-text">({{ $t('app.build') }} {{ build }})</span></v-btn></p>
          <p>{{ $t('about.text-p1') }}</p>
          <p>{{ $t('about.text-p2') }}</p>
          <p>{{ $t('about.text-p3') }}</p>
        </div>
      </v-card>
    </v-card>

    <v-dialog v-model="newsDialog" scrollable max-width="40%">
      <v-card height="80vh">
        <v-card-title class="text-h5">
          {{ $t('app.appname') }}{{ $t('app.version') }} - {{ $t('system.news') }}
        </v-card-title>

        <v-container class="my-0 py-0">
          <v-tabs v-model="tab">
            <v-tab>{{ $t('system.frontend') }}</v-tab>
            <v-tab>{{ $t('system.backend') }}</v-tab>
          </v-tabs>
        </v-container>
        <v-card-text class="my-0 py-0 mx-0 px-3">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="mx-2 news-text" v-html="frontNews" />
            </v-tab-item>
            <v-tab-item>
              <div class="mx-2 news-text" v-html="backNews" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="newsDialog = false">{{ $t('buttons.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { marked } from 'marked'
import TopBar from '@/components/TopBar'
import { mapActions, mapGetters } from 'vuex'

export default {
  metaInfo() {
    return {
      title: this.$t('about.label'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('about.label')
      }, {
        property: 'og:description',
        content: this.$t('about.default-meta-description')
      }]
    }
  },

  data: () => ({
    newsDialog: false,
    tab: 0
  }),

  components: {
    TopBar
  },

  created() {
    this.loadChangelog()
  },

  computed: {
    ...mapGetters('system', ['changelog']),

    build() {
      return process.env.VUE_APP_VERSION
    },

    frontNews() {
      return marked(process.env.VUE_APP_NEWS)
    },

    backNews() {
      return marked(this.changelog || '# No News')
    }
  },

  methods: {
    ...mapActions('system', ['loadChangelog']),
  }

}
</script>

<style>
  div.news-text h1 {
    font-size: 2em !important;
    font-weight: 300;
    line-height: 2.1em !important;
  }
  div.news-text h2 {
    font-size: 1.2em !important;
    font-weight: 300;
    line-height: 1.3em !important;
    margin: 20px 0px 10px 0px;
  }
</style>

<style scoped>
  .sneaky-button {
    height: inherit !important;
    min-width: 24px !important;
    padding: 0px !important;
    text-transform: inherit !important;
    font-family: "Roboto Condensed", sans-serif !important;
    line-height: 1.5 !important;
    font-weight: inherit !important;
  }
  .sneaky-button:hover::before {
    opacity: 0;
  }

  .text-container {
    width: 900px;
    position: relative;
    opacity: 0;
    margin-top: 40px;
    animation: fadeIn linear 0.5s;
    animation-delay: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;  
  }
  .animation-container {
    width: 900px;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  .ball img {
    position: absolute;
    top: -300px;
    left: 500px;
    height: 200px;
    width: 200px;
  }
  .letter {
    font-family: Roboto Condensed, sans-serif;
    position: absolute;
    top: 100px;
    font-size: 140px;
    font-weight: 700;
  }
  @keyframes bounce { 
    0% { transform: scale(1) translateY(0); }
    70% { transform: scale(1) translateY(350); }
    90% { transform: scale(0.95, 1.05) translateY(400px); }
    100% { transform: scale(1) translateY(400px); }
  }
  @keyframes slideM { 
    0% { transform: translatex(0); }
    100% { transform: translatex(500px); }
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  .letterM {
    animation: slideM 0.4s;
    left: -320px;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;  
  }
  .letterU {
    left: -220px;
    animation: slideM 0.5s;
    animation-delay: 0.1s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;  
  }
  .letterC {
    left: -155px;
    animation: slideM 0.6s;
    animation-delay: 0.2s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;  
  }
  .letterO {
    left: -80px;
    animation: slideM 0.7s;
    animation-delay: 0.3s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;  
  }
  .ball {
    animation: bounce 0.4s;
    animation-delay: 1s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;  
  }
</style>
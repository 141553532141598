<template>
  <LibraryList
    :title="$t('playlist.label')"
    :sortItems ="[
        { text: $t('playlist.search.alphabetic'), value: 'playlist' },
        { text: $t('playlist.search.creator'), value: 'creator' }
    ]"
    defaultSort="playlist"
    :storeConfig="{
      getter: 'playlists/libraryPlaylists',
      clearAction: 'playlists/clearLibraryPlaylists',
      fetchAction: 'playlists/getLibraryPlaylists'
    }"
  >
    <template v-slot="{row}">
      <PlaylistItem :showOwner="true" :item="row"></PlaylistItem>
    </template>
  </LibraryList>
</template>

<script>
import PlaylistItem from '@/components/PlaylistItem'
import LibraryList from '@/components/LibraryList'

export default {
  data: () => ({
    row: {},
  }),

  components: {
    PlaylistItem,
    LibraryList
  }
}
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LibraryList',{attrs:{"title":_vm.$t('artist.label'),"sortItems":[
    { text: _vm.$t('album.search.alphabetic'), value: 'artist' },
    { text: _vm.$t('artist.country'), value: 'country' }
  ],"defaultSort":"artist","storeConfig":{
    getter: 'artists/libraryArtists',
    clearAction: 'artists/clearLibraryArtists',
    fetchAction: 'artists/getLibraryArtists'
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var row = ref.row;
return [_c('ArtistItem',{attrs:{"item":row}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
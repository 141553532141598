var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LibraryList',{attrs:{"title":_vm.$t('playlist.label'),"sortItems":[
      { text: _vm.$t('playlist.search.alphabetic'), value: 'playlist' },
      { text: _vm.$t('playlist.search.creator'), value: 'creator' }
  ],"defaultSort":"playlist","storeConfig":{
    getter: 'playlists/libraryPlaylists',
    clearAction: 'playlists/clearLibraryPlaylists',
    fetchAction: 'playlists/getLibraryPlaylists'
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var row = ref.row;
return [_c('PlaylistItem',{attrs:{"showOwner":true,"item":row}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
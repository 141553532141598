<template>
  <v-card fluid class="fill-height view search px-4 pb-12 mt-12 mb-12">
    <TopBar>
      <template v-slot:title>{{ $t('search.search') }}</template>
      <v-text-field
        v-if="!$vuetify.breakpoint.mobile"
        class="topbar-search-field"
        hide-details
        :placeholder="$t('search.label')"
        :prepend-inner-icon="mdiMagnify"
        outlined
        rounded
        @input="debouncedOnChange"
        v-model="search"
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </TopBar>

    <v-card flat class="pt-4" v-if="$vuetify.breakpoint.mobile">
      <h5 class="pt-4">{{ $t('search.search') }}</h5>
      <v-card-text>
        <v-text-field
          hide-details
          :placeholder="$t('search.label')"
          :prepend-inner-icon="mdiMagnify"
          outlined
          rounded
          @input="debouncedOnChange"
          v-model="search"
          clearable
          @click:clear="clearSearch"
        ></v-text-field>
      </v-card-text>
    </v-card>

    <template v-if="searchHistory && searchHistory.length && !$vuetify.breakpoint.mobile">
      <v-card flat class="pt-2">
        <v-card-text>
          <div class="d-flex flex-row justify-space-between align-centre">
            <h5 class="mb-4">{{ $t('search.history') }}</h5>
            <template v-if="searchHistory.length > 6">
              <v-btn class="mt-n2" text v-if="!showAllHistory" @click="showAllHistory=true" data-test-id="showMoreBtn">{{ $t('search.more') }}</v-btn>
              <div v-if="showAllHistory">
                <v-btn class="mt-n2" text @click="clearHistory" data-test-id="clearAllBtn">{{ $t('search.clear') }}</v-btn>
                <v-btn class="mt-n2" text @click="showAllHistory=false" data-test-id="showLessBtn">{{ $t('search.less') }}</v-btn>
              </div>
            </template>
          </div>
          <v-row dense>
            <v-col v-for="(row,i) in filteredHistory" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
              <AlbumItem v-if="row.type==='album'" :item="row" showArtist closeable @close="closeItem" />
              <ArtistItem v-if="row.type==='artist'" :item="row" closeable @close="closeItem" />
              <PlaylistItem v-if="row.type==='playlist'" :item="row" showOwner closeable @close="closeItem" />
              <TrackItem v-if="row.type==='track'" :item="row" closeable @close="closeItem" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="!hasSearched && allUserGenres && genresLoaded">
      <v-card-text>
        <h5 class="mb-4">{{ $t('search.most-played-genres') }}</h5>
        <v-row>
          <v-col v-for="(userGenre,i) in topUserGenres" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <GenreCard :cardData="userGenre" :imageSize="150" :aspectRatio="3/2" />
          </v-col>
        </v-row>

        <h5 class="mb-4 mt-8">{{ $t('search.rest-of-played-genres') }}</h5>
        <v-row>
          <v-col v-for="(userGenre,i) in restUserGenres" :key="i" :cols="$vuetify.breakpoint.mobile ? 6 : 2">
            <GenreCard :cardData="userGenre" :imageSize="100" :aspectRatio="1/1" headingClass="text-h5" />
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <template v-if="hasSearched && anyResult">
      <h5 class="pt-4 mb-4">{{ $t('search.result') }}</h5>
      <v-chip v-for="(type,i) in Object.keys(searchResult)" :key="i" class="ma-2" :outlined="searchFilter===type ? false : true" color="primary" @click="setSearchFilter(type)">
        {{ chipNames[type] }}
      </v-chip>
      <v-card-text>
        <v-row dense>
          <v-col v-for="(row,i) in filteredResult" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
            <AlbumItem v-if="searchFilter==='albums'" :item="row" showArtist @clicked="addToHistory" />
            <ArtistItem v-if="searchFilter==='artists'" :item="row" @clicked="addToHistory" />
            <PlaylistItem v-if="searchFilter==='playlists'" :item="row" @clicked="addToHistory" />
            <TrackItem v-if="searchFilter==='tracks'" :item="row" @clicked="addToHistory" />
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <template v-if="hasSearched && !anyResult">
      <h5 class="mb-4">{{ $t('search.no-result') }}</h5>
    </template>

  </v-card>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import Ldebounce from 'lodash/debounce'
import Lget from 'lodash/get'
import LisEmpty from 'lodash/isEmpty'
import API from '../plugins/api'
import AlbumItem from '@/components/AlbumItem'
import ArtistItem from '@/components/ArtistItem'
import PlaylistItem from '@/components/PlaylistItem'
import TrackItem from '@/components/TrackItem'
import { mapGetters, mapActions } from 'vuex'
import TopBar from '@/components/TopBar'
import GenreCard from '@/components/GenreCard'
import { VibrantMixin } from '@/mixins'

export default {
  metaInfo() {
    return {
      title: this.$t('search.label'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('search.label')
      }, {
        property: 'og:description',
        content: this.$t('search.default-meta-description')
      }, {
        property: 'description',
        content: this.$t('search.default-meta-description')
      }]
    }
  },

  props: {
    cleanSearch: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      mdiMagnify,
      search: '',
      hasSearched: false,
      genresLoaded: false,
      showAllHistory: false,
      chipNames: {
        albums: this.$i18n.t('album.label'),
        artists: this.$i18n.t('artist.label'),
        playlists: this.$i18n.t('playlist.label'),
        tracks: this.$i18n.t('track.label')
      }
    }
  },

  components: {
    AlbumItem,
    ArtistItem,
    PlaylistItem,
    TrackItem,
    TopBar,
    GenreCard
  },

  mixins: [
    VibrantMixin
  ],

  mounted() {
    if (!this.$route.params.cleanSearch) {
      this.search = this.searchCriteria
    }
    this.load()
    if (Lget(this,'search.length', 0) > 2) {
      this.hasSearched = true
    }
  },

  computed: {
    ...mapGetters('search', ['searchResult','searchFilter', 'searchCriteria', 'searchHistory']),
    ...mapGetters('albums', ['userGenres']),

    debouncedOnChange() {
      return Ldebounce(this.doSearch, 500)
    },

    topUserGenres() {
      return this.allUserGenres.slice(0,4)
    },

    restUserGenres() {
      return this.allUserGenres.slice(4,100)
    },

    anyResult() {
      return !LisEmpty(this.searchResult)
    },

    filteredResult() {
      try {
        return this.searchResult[this.searchFilter].rows
      } catch(e) {
        return []
      }
    },

    filteredHistory() {
      return this.showAllHistory ? this.searchHistory : this.searchHistory.slice(0, 6)
    }
  },

  asyncComputed: {
    async allUserGenres() {
      return await Promise.all(Lget(this, 'userGenres.genres', []).map(async (o) => {
        if (o.backgroundColor) {
          return o
        }
        return await this.getColorPaletteFromBase64(o.imageSrc)
        .then((cp) => {
          const rgb = Lget(cp, 'Vibrant._rgb', null)
          if (rgb) {
            Object.assign(o, {backgroundColor: `rgb(${rgb.join(', ')})`})
            return this.updateUserGenre(o)
          }
          Object.assign(o, {backgroundColor: 'rgb(255,0,0)'})
          return this.updateUserGenre(o)
        })
      }))
    },
  },

  methods: {
    ...mapActions('search', [
      'setSearchResult', 'setSearchFilter', 'setSearchCriteria',
      'getSearchHistory', 'addToSearchHistory', 'removeFromSearchHistory', 'clearSearchHistory']),
    ...mapActions('albums', ['getUserGenres', 'updateUserGenre']),

    Lget: Lget,

    async closeItem(item) {
      await this.removeFromSearchHistory(item)
    },

    async load() {
      await this.getSearchHistory()
      await this.getUserGenres()
      this.genresLoaded = true
    },

    async addToHistory(item) {
      await this.addToSearchHistory(item)
    },

    async clearHistory() {
      await this.clearSearchHistory()
      this.showAllHistory = false
    },

    async clearSearch() {
      this.search = ""
      await this.setSearchResult({})
      await this.setSearchCriteria(this.search)
      await this.setSearchFilter(null)
      this.hasSearched = false
    },

    async doSearch() {
      if (Lget(this,'search.length', 0) > 2) {
        const res = await API.search.all({search: this.search})
        await this.setSearchResult(Lget(res,'data',{}))
        await this.setSearchCriteria(this.search)
        await this.setSearchFilter(Object.keys(this.searchResult) ? Object.keys(this.searchResult)[0] : null)
        this.hasSearched = true
        return
      }
    },
  }

}
</script>

<style>
.topbar-search-field > .v-input__control > .v-input__slot {
  margin-top: 3px;
  max-height: 32px !important;
  min-height: 42px !important;
}
.topbar-search-field > .v-input__control > .v-input__slot > .v-input__prepend-inner, 
.topbar-search-field > .v-input__control > .v-input__slot > .v-input__append-inner{
  margin-top: 8px;
}

</style>
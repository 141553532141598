<template>
  <LibraryList
    :title="$t('artist.label')"
    :sortItems ="[
      { text: $t('album.search.alphabetic'), value: 'artist' },
      { text: $t('artist.country'), value: 'country' }
    ]"
    defaultSort="artist"
    :storeConfig="{
      getter: 'artists/libraryArtists',
      clearAction: 'artists/clearLibraryArtists',
      fetchAction: 'artists/getLibraryArtists'
    }"
  >
    <template v-slot="{row}">
      <ArtistItem :item="row"></ArtistItem>
    </template>
  </LibraryList>
</template>

<script>
import ArtistItem from '@/components/ArtistItem'
import LibraryList from '@/components/LibraryList'

export default {
  data: () => ({
    row: {},
  }),

  components: {
    ArtistItem,
    LibraryList
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card @click="showAlbum(item)" :class="$vuetify.breakpoint.mobile ? 'ma-0 pa-0' : 'ma-4'" @mouseover="hover = true" @mouseleave="hover = null">

    <v-list-item v-if="$vuetify.breakpoint.mobile">
      <v-list-item-avatar tile left :size="imageSize">
        <v-img :height="imageSize" :width="imageSize" :src="Lget(item, 'album.imageSrc', '')" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="item-texts">{{ Lget(item, 'songName', '') }}X</v-list-item-title>
        <v-list-item-subtitle class="shortcut-texts">{{ Lget(item, 'album.albumName', '') }} &bull; {{ Lget(item, 'album.artist.artistName', '') }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <template v-else>
      <v-img height="200" width="200" :src="Lget(item, 'album.imageSrc', '')">
        <template v-if="closeable">
          <v-btn v-show="hover" class="mt-6 mr-n2" fab absolute x-small top right @click.native.stop="$emit('close', item)" transition="fade-transition">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-img>
      <div class="card-text-cnt">
        <v-card-title class="card-text-item my-0 pt-2 pb-0 ml-n4">{{ Lget(item, 'songName', '') }}</v-card-title>
        <v-card-subtitle class="shortcut-texts mt-0 ml-n4">{{ Lget(item, 'album.albumName', '') }} &bull; {{ Lget(item, 'album.artist.artistName', '') }}</v-card-subtitle>
      </div>
    </template>

  </v-card>

</template>

<script>
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import Lget from 'lodash/get'

export default {

  props: {
    item: Object,
    closeable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    hover: false,
    mdiClose
  }),

  computed: {
    imageSize() {
      return this.$vuetify.breakpoint.mobile ? 40 : 80
    }
  },

  methods: {
    Lget: Lget,

    showAlbum(song) {
      this.$nextTick(() => {
        this.$emit('clicked', {type: 'track', ...song})
        this.$router.push({ name: 'Album', params: {id: song.album.id } }).catch(err => {})
      })
    }
  }
}
</script>

<style scoped>
.item-texts {
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-text-cnt {
  max-width: 200px;
}
.card-text-item {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
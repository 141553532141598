<template>
  <v-card fluid class="fill-height view library mb-12 pb-12" :class="{'px-4': !$vuetify.breakpoint.mobile}">
    <TopBar>
      <template v-slot:title v-if="!$vuetify.breakpoint.mobile" >{{ $t('library.library') }}</template>
      <template>
        <v-tabs v-model="tab">
          <v-tab class="library-tabs" v-for="(type,i) in Object.keys(chipNames)" :key="i" :href="`#${type}`">
            {{ chipNames[type] }}
          </v-tab>
        </v-tabs>
      </template>
    </TopBar>

    <v-card flat v-for="(type,i) in Object.keys(chipNames)" :key="i" class="transparent-background">
      <v-tabs-items :value="tab">
        <v-tab-item :value="type">
          <v-card-text>
            <AlbumList v-if="type==='albums'" />
            <ArtistList v-if="type==='artists'" />
            <PlaylistList v-if="type==='playlists'" />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AlbumList from '@/components/AlbumList'
import ArtistList from '@/components/ArtistList'
import PlaylistList from '@/components/PlaylistList'
import TopBar from '@/components/TopBar'

export default {
  metaInfo() {
    return {
      title: this.$t('library.library'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('library.library')
      }, {
        property: 'og:description',
        content: this.$t('library.default-meta-description')
      }]
    }
  },

  data: function () {
    return {
      chipNames: {
        playlists: this.$i18n.t('playlist.label'),
        albums: this.$i18n.t('album.label'),
        artists: this.$i18n.t('artist.label'),
      }
    }
  },

  components: {
    AlbumList,
    ArtistList,
    PlaylistList,
    TopBar
  },

  computed: {
    tab: {
      set (tab) {
        this.$router.push({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab || 'playlist'
      }
    }
  }
}
</script>

<style scoped>
.library-tabs {
  text-transform: initial !important;
  font-size: 1rem !important;
}
</style>
<template>
  <LibraryList
    :title="$t('album.label')"
    :sortItems ="[
      { text: $t('album.search.alphabetic'), value: 'album' },
      { text: $t('artist.label'), value: 'artist' },
      { text: $t('album.addedDate'), value: 'date' }
    ]"
    defaultSort="album"
    :storeConfig="{
      getter: 'albums/libraryAlbums',
      clearAction: 'albums/clearLibraryAlbums',
      fetchAction: 'albums/getLibraryAlbums'
    }"
  >
    <template v-slot="{row}">
      <AlbumItem :item="row" showArtist></AlbumItem>
    </template>
  </LibraryList>
</template>

<script>
import AlbumItem from '@/components/AlbumItem'
import LibraryList from '@/components/LibraryList'

export default {
  data: () => ({
    row: {},
  }),

  components: {
    AlbumItem,
    LibraryList
  }
}
</script>

<style scoped>

</style>
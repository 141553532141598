var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LibraryList',{attrs:{"title":_vm.$t('album.label'),"sortItems":[
    { text: _vm.$t('album.search.alphabetic'), value: 'album' },
    { text: _vm.$t('artist.label'), value: 'artist' },
    { text: _vm.$t('album.addedDate'), value: 'date' }
  ],"defaultSort":"album","storeConfig":{
    getter: 'albums/libraryAlbums',
    clearAction: 'albums/clearLibraryAlbums',
    fetchAction: 'albums/getLibraryAlbums'
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var row = ref.row;
return [_c('AlbumItem',{attrs:{"item":row,"showArtist":""}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }